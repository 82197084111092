import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga4';

import AboutPage from './components/Pages/AboutPage';
import BlogPage from './components/Pages/BlogPage';
import ContactPage from './components/Pages/ContactPage';
import ErrorPage from './components/Pages/ErrorPage';
import Home from './components/Pages/Home';

import TeamPage from './components/Pages/TeamPage';
import BlogHowNot from './components/Pages/BlogHowNot';
import BlogNFTDetails from './components/Pages/BlogNFTDetails';
import BlogUtilityNftDetails from './components/Pages/BlogUtilityNftDetails';
import BlogWeb3Biz from './components/Pages/BlogWeb3Biz';
import BlogTodoDapp from './components/Pages/BlogTodoDapp';
import BlogSoa from './components/Pages/BlogSoa';

import BlogDataDetails from './components/Pages/BlogDataDetails';
import Mehchant from './components/Pages/Mehchant';
import FanEconomy from './components/Pages/FanEconomy';

import Layout from './components/Layout';
import FaqPage from './components/Pages/FaqPage';
import Litepaper from './components/Pages/Litepaper';
import Projects from './components/Pages/Projects';
import Portfolio from './components/Pages/Portfolio';
import Activation from './components/Pages/Activation';
import Merchandising from './components/Pages/Merchandising';



function App() {

  useEffect(() => {
    const MEASUREMENT_ID = "G-V1NWL3Y9DL";
    ReactGA.initialize(MEASUREMENT_ID);

    const sendPageView = () => {
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    };

    sendPageView();
    window.addEventListener('popstate', sendPageView);

    return () => {
      window.removeEventListener('popstate', sendPageView);
    };
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />

          <Route path="about" element={<AboutPage />} />

          <Route path="projects" element={<Projects />} />
          <Route path="projects/merchandising" element={<Merchandising />} />
          <Route path="projects/FanEconomy" element={<FanEconomy/>} />
          <Route path="projects/activation" element={<Activation />} />
          <Route path="projects/mehchant" element={<Mehchant />} />
          <Route path="/portfolio" element={<Portfolio />} />
          
          <Route path="blog" element={<BlogPage />} />
          <Route path="blog/a-case-for-nfts" element={<BlogNFTDetails/>} />
          <Route path="blog/how-not-to-start-a-web3-biz" element={<BlogHowNot/>} />
          <Route path="blog/a-case-for-data-engineers" element={<BlogDataDetails/>} />
          <Route path="blog/web3-soa" element={<BlogSoa />} />
          <Route path="blog/utility-based-nfts" element={<BlogUtilityNftDetails/>} />
          <Route path="blog/todo-dapp" element={<BlogTodoDapp/>} />
          <Route path="blog/important-roles-in-web3" element={<BlogWeb3Biz/>}/>
          

          <Route path="contact" element={<ContactPage />} />
          <Route path="team" element={<TeamPage />} />

          <Route
            path="/litepaper"
            element={<Litepaper />}
          />
          <Route path="faq" element={<FaqPage />} />
        </Route>

        

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
